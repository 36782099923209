// --theme-background-color-light-dark 代表不同主题几级色调
/**
 * 即将废弃，请使用--spec和--skin
 */
import { ColorMap } from './types';

export const colorMap: ColorMap = {
  // 首要主题背景色
  '--theme-primary-bg-color': {
    light: '#dfdfdf',
    dark: '#111111',
  },
  // 次要主题背景色
  '--theme-secondary-bg-color': {
    light: '#f4f4ec',
    dark: '#202023',
  },
  '--theme-secondary-bg-color-1': {
    light: '#f4f4ec',
    dark: '#202023',
  },
  '--theme-secondary-bg-color-2': {
    light: '#ffffff',
    dark: '#313535',
  },
  // 次要主题背景色
  '--theme-secondary-bg-color-3': {
    light: '#f4f4ec',
    dark: '#202023',
  },
  // 次要主题背景色
  '--theme-secondary-bg-color-4': {
    light: '#f4f4ec',
    dark: '#202323',
  },
  // 一级文字颜色
  '--theme-font-color-1': {
    dark: '#FFFFFF',
    light: '#141717',
  },
  '--theme-font-color-1-half': {
    dark: 'rgba(255, 255, 255, 0.5)',
    light: 'rgba(20, 23, 23, 0.5)',
  },
  // 一级文字颜色反转
  '--theme-font-color-1-reverse': {
    dark: '#141717',
    light: '#ffffff',
  },
  // 二级文字颜色
  '--theme-font-color-2': {
    dark: '#737473',
    light: '#737473',
  },
  // 三级文字颜色
  '--theme-font-color-3': {
    dark: '#9e9e9d',
    light: '#9e9e9d',
  },
  // 四级文字颜色
  '--theme-font-color-4': {
    dark: '#ffffff',
    light: '#42413D',
  },
  // 五级文字颜色
  '--theme-font-color-5': {
    dark: '#9E9E9D',
    light: '#141717',
  },
  // 六级文字颜色
  '--theme-font-color-6': {
    dark: '#ffffff',
    light: '#000000',
  },
  '--theme-font-color-6-1': {
    dark: '#ffffff',
    light: '#323232',
  },
  '--theme-font-color-7': {
    dark: '#141717',
    light: '#141717',
  },
  // 占位文字颜色
  '--theme-font-color-placeholder': {
    dark: '#737473',
    light: '#c5c5c4',
  },
  // 一级描边颜色
  '--theme-border-color-1': {
    dark: 'rgba(81, 86, 86, 0.5)',
    light: '#e5e5e4',
  },
  '--theme-border-color-1-1': {
    dark: 'rgba(64, 69, 69, 0.5)',
    light: 'rgba(229,229,228,0.5)',
  },
  // 二级描边颜色
  '--theme-border-color-2': {
    dark: '#404545',
    light: '#f2f2f0',
  },
  // 三级描边颜色
  '--theme-border-color-3': {
    dark: '#404545',
    light: '#E3E3DC',
  },
  // 四级描边颜色
  '--theme-border-color-4': {
    dark: 'rgba(64,69,69,0.5)',
    light: 'rgba(227,227,220,0.5)',
  },
  // 五级描边颜色
  '--theme-border-color-5': {
    dark: 'rgba(81, 86, 86, 0.5)',
    light: '#f2f2f0',
  },
  // 六级描边颜色
  '--theme-border-color-6': {
    dark: '#fff',
    light: '#9e9e9d',
  },
  '--theme-border-color-7': {
    dark: 'rgba(81, 86, 86, 0.50)',
    light: 'rgba(224, 224, 223, 0.50)',
  },
  // 一级背景颜色
  '--theme-background-color-1': {
    dark: '#202323',
    light: '#fafafa',
  },
  // 二级背景颜色
  '--theme-background-color-2': {
    dark: '#313535',
    light: '#ffffff',
  },
  '--theme-background-color-2-1': {
    dark: '#2B2E2E',
    light: '#ffffff',
  },
  '--theme-background-color-2-2': {
    dark: '#2B2E2E',
    light: '#fafafa',
  },
  '--theme-background-color-2-3': {
    dark: '#3E4343',
    light: '#ffffff',
  },
  '--theme-background-color-2-4': {
    dark: '#3E4343',
    light: '#f2f2f0',
  },
  '--theme-background-color-2-5': {
    dark: 'rgba(62,67,67, 0.5)',
    light: 'rgba(242,242,240,0.5)',
  },
  // 三级背景颜色
  '--theme-background-color-3': {
    dark: 'rgba(62, 67, 67, 0.5)',
    light: '#f5f5f3',
  },
  '--theme-background-color-3-1': {
    dark: '#202323',
    light: '#f5f5f3',
  },
  '--theme-background-color-3-2': {
    dark: '#313535',
    light: '#f5f5f3',
  },
  '--theme-background-color-3-3': {
    dark: 'rgba(62, 67, 67, 1)',
    light: '#f5f5f3',
  },
  '--theme-background-color-3-4': {
    dark: '#2a2a2c',
    light: '#f5f5f3',
  },
  '--theme-background-color-3-5': {
    dark: '#313535',
    light: '#f0f0ee',
  },
  '--theme-background-color-4': {
    dark: '#3E4343',
    light: '#f9f9f9',
  },
  '--theme-background-color-5': {
    dark: '#292C2C',
    light: '#F5F5F3',
  },
  '--theme-background-color-6': {
    dark: '#292C2C',
    light: '#F0F0EE',
  },
  '--theme-background-color-7': {
    dark: '#202323',
    light: '#EAEAE0',
  },
  '--theme-background-color-8': {
    dark: '#3E4343',
    light: '#F5F5F3',
  },
  '--theme-background-color-8-1': {
    dark: 'rgba(62,67,67,0.5)',
    light: 'rgba(245,245,243,0.5)',
  },
  // 交易页面Header使用
  '--theme-background-color-9': {
    dark: '#202323',
    light: '#ffffff',
  },
  '--theme-background-color-10': {
    dark: 'rgba(73,81,87,0.15)',
    light: '#EAF5FF',
  },
  '--theme-background-color-11': {
    dark: '#292c2c',
    light: '#F9F9F9',
  },
  '--theme-background-color-12': {
    dark: '#292c2c',
    light: '#f4f4ec',
  },
  '--theme-background-color-13': {
    dark: '#292c2c',
    light: '#ffffff',
  },
  '--theme-background-color-14': {
    dark: '#3E4343',
    light: '#F0F0EE',
  },
  '--theme-background-color-15': {
    dark: '#202323',
    light: '#CDCDB8',
  },
  '--theme-background-color-16': {
    dark: '#292C2C',
    light: '#F7F7F1',
  },
  '--theme-background-color-17': {
    dark: '#313636',
    light: '#ffffff',
  },
  '--theme-sub-button-bg': {
    dark: '#3E4343',
    light: '#F5F5F3',
  },
  '--theme-sub-button-bg-1': {
    dark: '#4C5252',
    light: '#fff',
  },
  '--theme-sub-button-bg-2': {
    dark: '#4C5252',
    light: '#F5F5F3',
  },
  '--theme-sub-button-bg-3': {
    dark: '#3e4343',
    light: 'linear-gradient(45deg, #fffcec, #fff)',
  },
  '--theme-sub-button-bg-4': {
    dark: '#4C5252',
    light: '#D9D9D9',
  },
  '--theme-sub-button-bg-5': {
    dark: '#4C5252',
    light: '#c5c5c4',
  },
  // 不可点击背景
  '--theme-background-color-disabled': {
    dark: '#4C5252',
    light: '#e6e6e4',
  },
  // 浅色不可点击背景
  '--theme-background-color-disabled-light': {
    dark: '#4C5252',
    light: '#E5E5E4',
  },
  // 深色不可点击背景
  '--theme-background-color-disabled-dark': {
    dark: '#4C5252',
    light: '#F0F0EE',
  },
  '--theme-input-placeholder-color': {
    dark: '#9E9E9D',
    light: '#9e9e9d',
  },
  '--theme-tips-color': {
    dark: '#3E4343',
    light: '#F5F5F3',
  },
  '--theme-tips-color-2': {
    dark: '#3E4343',
    light: '#ffffff',
  },
  '--theme-btn-font-color': {
    dark: '#141717',
    light: '#141717',
  },
  '--theme-linear-gradient-right-color': {
    dark: 'linear-gradient(80deg, rgba(41, 44, 44, 0.00) 0%, #292C2C 80%)',
    light: 'linear-gradient(80deg, rgba(245, 245, 243,0) 0%, #F5F5F3 80%)',
  },
  '--theme-linear-gradient-left-color': {
    dark: 'linear-gradient(80deg, #292C2C 0%, rgba(41, 44, 44, 0.00) 100%)',
    light: 'linear-gradient(80deg, #F5F5F3 0%, rgba(245, 245, 243,0) 100%)',
  },
  '--theme-linear-gradient-fiat-crypto': {
    dark: 'linear-gradient(0deg, #313535 21.11%, rgba(49, 53, 53, 0.00) 84.35%)',
    light: 'linear-gradient(0deg, #FFF 7.78%, rgba(255, 255, 255, 0.00) 100%)',
  },
  // 深色一级描边颜色
  '--theme-deep-border-color-1': {
    dark: '#515656',
    light: '#F5F5F3',
  },
  '--theme-deep-border-color-1-1': {
    dark: '#515656',
    light: '#D9D9D9',
  },
  // 黑白色
  '--theme-common-color': {
    dark: '#ffffff',
    light: '#000000',
  },
  // 代理中心表格间隔色
  '--theme-table-odd-background': {
    dark: '#3E4343',
    light: '#F8F8F8',
  },
  // 现货交易模块颜色
  '--theme-trade-background': {
    dark: '#2A2E2E',
    light: '#ffffff',
  },
  '--theme-kline-header-color': {
    dark: 'rgba(158, 158, 157, 1)',
    light: 'rgba(158, 158, 157, 1)',
  },
  '--theme-brand-line-color': {
    dark: 'rgba(81, 86, 86, 0.5)',
    light: 'rgba(229, 229, 228, 0.5)',
  },
  '--theme-brand-round-color': {
    dark: 'rgba(81, 86, 86, 1)',
    light: 'rgba(229, 229, 228, 1)',
  },
  // join affiliate box shadow
  '--theme-box-shadow-1': {
    dark: '1px 0px 10px 0px rgba(0,0,0,0.3)',
    light: '4px 4px 16px 0px rgba(224, 216, 177, 0.3)',
  },
  '--theme-box-shadow-2': {
    dark: '0px 5px 10px rgba(0, 0, 0, 0.3)',
    light: '0px 5px 10px rgba(0, 0, 0, 0.08)',
  },
  '--theme-card-bg-color': {
    dark: 'rgba(0, 0, 0, 0.15)',
    light: 'rgba(255,255, 255, 0.15)',
  },
  '--theme-demo-bg-color': {
    dark: '#202323',
    light: '#ECF1F4',
  },
  '--theme-grid-card-color': {
    dark: '#323636',
    light: '#f5f5f5',
  },
  '--theme-grid-card-hover-color': {
    dark: '#3E4343',
    light: '#e5e5e5',
  },
  '--theme-grid-card-hover-divider-color': {
    dark: '#4C5252',
    light: '#dddddd',
  },
  '--theme-grid-back-color': {
    dark: '#323636',
    light: '#E0E0DF',
  },
  '--theme-grid-button-color': {
    dark: '#515656',
    light: '#d9d9d9',
  },
  '--theme-grid-tips-color': {
    dark: 'rgba(240, 78, 63, 0.15)',
    light: 'rgba(180, 169, 130, 0.15)',
  },
  '--theme-grid-profit-color': {
    dark: '#737473',
    light: '#D9D9D9',
  },
  '--theme-lottery-btn-color': {
    dark: '#282C2C',
    light: '#F4F4EC',
  },
};
